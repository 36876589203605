import React, { useCallback } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import useWindow from "@hooks/useWindow";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";
import { GLOBAL_APPLICATION_EXTERNAL_LINKS } from "@links"

export default function useLinks() {
    const isWindowDefined = useWindow();

    const generateLinks = useCallback(
        (links, isMainLink, path, setShowLinks, icon) => {
            const LinkElements = [];

            links.forEach(el => {
                LinkElements.push(
                    <>
                        {isMainLink ? (
                            <MainLinkContainer>
                                <MainLink
                                    onClick={() => {
                                        if (setShowLinks && el.path === path) {
                                            setTimeout(() => {
                                                setShowLinks(false);
                                            }, 1000);
                                        } else {
                                            navigate(el.path);
                                        }
                                    }}
                                >
                                    {el.linkName}
                                </MainLink>
                                {icon ?? null}
                            </MainLinkContainer>
                        ) : (
                            <SecondaryLink
                                onClick={() => {
                                    if (el.isExternal && !isWindowDefined) {
                                        window.open(GLOBAL_APPLICATION_EXTERNAL_LINKS.STARTUP_KIT, "_blank");
                                    } else if (
                                        setShowLinks &&
                                        path &&
                                        el.path === path
                                    ) {
                                        setTimeout(() => {
                                            setShowLinks(false);
                                        }, 1000);
                                    } else {
                                        navigate(el.path);
                                    }
                                }}
                            >
                                {el.linkName}
                            </SecondaryLink>
                        )}
                    </>
                );
            });

            return LinkElements;
        },
        []
    );

    return generateLinks;
}

const MainLinkContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;

    & img, svg {
        margin-top: 1rem;
        max-width: 2.5rem;
        height: fit-content;
    }

    &:hover svg path,
    &:hover svg rect {
        fill: #c4b180;
    }

    &:hover h2 {
        color: #c4b180;
    }
`

const MainLink = styled.h2`
    color: rgba(255, 255, 255, 0.8);
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 3rem;
    margin: 1.25rem 0 0;
    padding-bottom: 0.75rem;
    width: fit-content;
    cursor: pointer;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        font-size: 1.75rem;
        line-height: 2.25rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 1.25rem;
        line-height: 1.875rem;
    }
`;

const SecondaryLink = styled.p`
    color: rgba(255, 255, 255, 0.8);
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.875rem;
    margin-top: 1.5rem;
    width: fit-content;
    cursor: pointer;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        line-height: 1.5rem;
    }

    &:hover {
        color: #c4b180;
    }
`;
