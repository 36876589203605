import React, { useState, useContext } from "react"
import infoIcon from "@images/common/logo-icon.svg"
import { StyledActionButton, StyledInfoIcon } from "../../common-styles"
import { CookieContext } from "../../CookieContext"
import { withConfig } from "../../withConfig"
import { ConsentContext } from "../../../../../contexts/ConsentContext"
import { ToggleButton } from ".."
import {
  ACTION_BUTTONS_IDS,
  ACTION_BUTTONS_TEXTS,
  CONSENT_BANNER_CLASSNAME,
  CONSENT_BANNER_DESCRIPTION,
  CONSENT_BANNER_ID,
  CONSENT_BANNER_TITLE,
  CONSENT_MODE_CONFIGURATIONS,
  GOOGLE_TAG_FLAGS,
  INFO_ICON_ALTERNATIVE_TEXT,
  TABLET_TOGGLE_STYLES,
  TOGGLE_BUTTONS_IDS,
  TOGGLE_BUTTONS_TEXTS,
} from "../../constants"
import {
  StyledTabletConsentBannerWrapper,
  StyledTabletConsentBannerContainer,
  StyledIconContainer,
  StyledTextContainer,
  StyledTextContainerTitle,
  StyledTextContainerDescription,
  StyledConsentOptionsContainer,
  StyledConsentOptionContainer,
  StyledConsentBannerFooter,
  StyledActionsButtonContainer,
} from "./styled"

export const TabletConsentBanner = ({ config }) => {
  const [selectedOptions, setSelectedOptions] = useState(CONSENT_MODE_CONFIGURATIONS.DEFAULT);
  const { setUserToken } = useContext(CookieContext);
  const { collectConsentData } = useContext(ConsentContext)

  const TOGGLE_BUTTONS = [
    { id: TOGGLE_BUTTONS_IDS.NECESSARY, isNecessary: true, text: TOGGLE_BUTTONS_TEXTS.NECESSARY },
    { id: TOGGLE_BUTTONS_IDS.ANALYTICS, text: TOGGLE_BUTTONS_TEXTS.ANALYTICS, onToggle: () => config.findSelectedOptions(setSelectedOptions, GOOGLE_TAG_FLAGS.ANALYTICS_STORAGE)},
    { id: TOGGLE_BUTTONS_IDS.MARKETING, text: TOGGLE_BUTTONS_TEXTS.MARKETING, onToggle: () => config.findSelectedOptions(setSelectedOptions, GOOGLE_TAG_FLAGS.AD_STORAGE, GOOGLE_TAG_FLAGS.AD_USER_DATA)},
    { id: TOGGLE_BUTTONS_IDS.PREFERENCES, text: TOGGLE_BUTTONS_TEXTS.PREFERENCES, onToggle: () => config.findSelectedOptions(setSelectedOptions, GOOGLE_TAG_FLAGS.PERSONALIZATION_STORAGE)},
    { id: TOGGLE_BUTTONS_IDS.PARTNERS, text: TOGGLE_BUTTONS_TEXTS.PARTNERS, onToggle: () => config.findSelectedOptions(setSelectedOptions, GOOGLE_TAG_FLAGS.AD_PERSONALIZATION)},
  ];

  const ACTION_BUTTONS = [
    {
      id: ACTION_BUTTONS_IDS.ACCEPT_ALL,
      text: ACTION_BUTTONS_TEXTS.ACCEPT_ALL,
      isAllowButton: true,
      onClick: () => {
        config.acceptAll()
        collectConsentData("Allow All")
      },
    },
    {
      id: ACTION_BUTTONS_IDS.ALLOW_SELECTION,
      text: ACTION_BUTTONS_TEXTS.ALLOW_SELECTION,
      onClick: () => {
        config.collect(selectedOptions)
        collectConsentData("Allow Selection")
      },
    },
    {
      id: ACTION_BUTTONS_IDS.REJECT_ALL,
      text: ACTION_BUTTONS_TEXTS.REJECT_ALL,
      onClick: () => {
        config.rejectAll()
        collectConsentData("Reject All")
      },
    },
  ]

  return (
    <StyledTabletConsentBannerWrapper
      id={CONSENT_BANNER_ID}
      className={CONSENT_BANNER_CLASSNAME}
    >
      <StyledTabletConsentBannerContainer>
        <StyledIconContainer>
          <StyledInfoIcon src={infoIcon} alt={INFO_ICON_ALTERNATIVE_TEXT} />
        </StyledIconContainer>
        <StyledTextContainer>
          <StyledTextContainerTitle>
            {CONSENT_BANNER_TITLE}
          </StyledTextContainerTitle>
          <StyledTextContainerDescription>
            {CONSENT_BANNER_DESCRIPTION}
          </StyledTextContainerDescription>
        </StyledTextContainer>
        <StyledConsentOptionsContainer>
          {TOGGLE_BUTTONS.map(props => (
            <StyledConsentOptionContainer>
              <ToggleButton {...props} toggleStyles={TABLET_TOGGLE_STYLES} />
            </StyledConsentOptionContainer>
          ))}
        </StyledConsentOptionsContainer>
        <StyledConsentBannerFooter>
          <StyledActionsButtonContainer>
            {ACTION_BUTTONS.map(({ id, text, isAllowButton, onClick }) => (
              <StyledActionButton
                id={id}
                isAllowButton={isAllowButton}
                onClick={() => {
                  onClick();
                  setUserToken(true);
                }}
                style={{ flex: "0.32" }}
              >
                {text}
              </StyledActionButton>
            ))}
          </StyledActionsButtonContainer>
        </StyledConsentBannerFooter>
      </StyledTabletConsentBannerContainer>
    </StyledTabletConsentBannerWrapper>
  )
}

export default withConfig(TabletConsentBanner)
