import React, { useState } from "react";
import arrowUp from "@images/common/ic-arrow-up.svg";
import { StyledArrowUp } from "./styled";

export default function ArrowUp({ backgroundColor }) {
    const [showTopButton, setShowTopButton] = useState(false);

    const isPageScrolled = () => {
        if (document.documentElement.scrollTop > 60) {
            setShowTopButton(true);
        } else {
            setShowTopButton(false);
        }
    };

    const toTop = () => {
        document.documentElement.scrollTop = 0;
    };

    if (typeof window !== "undefined") {
        window.addEventListener("scroll", isPageScrolled);
    }

    return (
        showTopButton && (
            <StyledArrowUp
                src={arrowUp}
                backgroundColor={backgroundColor}
                onClick={toTop}
                alt="Up"
            />
        )
    );
}
